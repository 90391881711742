// 
// user.scss
// Use this to write your custom SCSS
//



@import '../../node_modules/tom-select/src/scss/tom-select.bootstrap5.scss';

body
{
	overflow-y: scroll;
}

.btn span.fe
{
    margin-bottom: 2px;
    display: inline-block;
    vertical-align: text-bottom;
	line-height: 1;
}
.dropdown-item span.fe
{
    margin-bottom: 3px;
    display: inline-block;
    vertical-align: text-bottom;
}
 
.btn:disabled,
.btn.disabled
{
    opacity: 1;
    border-color: $gray-200;
    color: $gray-600;
    background: $gray-200;
	cursor: not-allowed !important;
	pointer-events: all !important;
}

.btn-auth
{
	min-width: 220px;

	img 
	{
		display: inline-block;
		height: 18px;
		vertical-align: middle;
		width: 18px;
    	margin: 0 0.5rem 3px 0;
	}
}

.btn-github
{
    border-color: #333333;
    color: #fff;
	background-color: #333333;
	
	&:hover
	{
		color: #fff;
	}

	&:focus
	{
		box-shadow: 0 0 0 0.15rem rgba(223, 226, 230, 0.5);
	}
}

.btn-facebook
{
    border-color: #3b5998;
    color: #fff;
	background-color: #3b5998;
	
	&:hover
	{
		color: #fff;
	}

	&:focus
	{
		box-shadow: 0 0 0 0.15rem rgba(223, 226, 230, 0.5);
	}
}

.btn-twitter
{
    border-color: #55acee;
    color: #fff;
	background-color: #55acee;
	
	&:hover
	{
		color: #fff;
	}

	&:focus
	{
		box-shadow: 0 0 0 0.15rem rgba(223, 226, 230, 0.5);
	}
}

i
{
    font-style: normal;
}

.dropdown-item:disabled
{
	color: $btn-link-disabled-color !important;
}

.dropdown-ellipses
{
	color: $gray-500;
}


.feedback
{
	display: none;
}

.invalid-feedback
{
	display: block;

	a
	{
		text-decoration: underline;
		color: $danger;
	}
}

.toast-msg
{
	position: absolute;
	width: 100%;
	z-index: 1;
	text-align: center;
	padding: 0.6rem;
	opacity: 1;
	transition: opacity 250ms;

	&.fade-out
	{
		opacity: 0;
	}
}

a:focus
{
	text-decoration: underline;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  padding-right: 0.25rem;
}

.input-button-right
{
	position: relative;

	input
	{
		padding-right: 2rem;
	}

	button
	{
		position: absolute;
		right: 0.8rem;
		top: 0.6rem;

		&:focus
		{
			box-shadow: none;
		}
	}
}

.feedback-overlay
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background: #fff;


	.img
	{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		
		background-repeat: no-repeat;
		background-position: top center;
		z-index: 0;
		filter: blur(0px);
		opacity: 1;

		transition: 200ms filter linear
				  , 200ms opacity linear;

		&.blur
		{
			filter: blur(30px);
			opacity: 0.4;
		}
	}

	.questions
	{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

}

.pg
{
	opacity: 0;
	transition: opacity 100ms ease-in-out;
}

#page-loading
{
	position: absolute;
	width: 100%;
}

.feedback-project
{
	.col-sidebar
	{
		box-shadow: 0px 5px 7px rgba(0,0,0,0.1);
		z-index: 99;
		height: 100vh;
		
		.questions
		{
			height: 100%;

			.messages
			{
				display: flex;
				flex-direction: column;
				scroll-behavior: smooth;
				
				> :first-child
				{
					margin-top: auto;
					padding-top: 1rem;
				}

				> :last-child
				{
					padding-bottom: 1rem;
				}

				> *
				{
					opacity: 1;
					transition: opacity 250ms ease-out;
				}

				.new
				{
					opacity: 0;
				}

				textarea
				{
					resize: none;
				}

				input[type=number]
				{
					-moz-appearance: textfield;
					-webkit-appearance: textfield;
					appearance: textfield;
				}
			}
		
		}
	}

	.col-website 
	{
		overflow-y: auto;
    	height: 100vh;

		.spinner-border
		{
			position: absolute;
			top: 40px;
			left: 50%;
			margin-left: -20px;
			z-index: 1;
		}

		.img-container
		{
			position: relative;
			display: inline-block;

			.box
			{
				position: absolute;
				border: 4px solid #000;
				border-radius: 10px;
			}
		}

		img
		{
			filter: blur(0px);
			opacity: 1;

			transition: 200ms filter linear
					, 200ms opacity linear;

			&.blur
			{
				filter: blur(40px);
				opacity: 0.4;
			}
		}

		.intro
		{
			position: absolute;
			top: 200px;
			left: 50%;
			margin-left: -200px;
			width: 400px;
		}

		&.overlaid
		{
			.img-container
			{
				background: #000;
			}
			
			img {
				opacity: 0.5;
			}
		}

		.overlay
		{
			display: none;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-size: 100%;
			background-position: 0 0;
			background-repeat: no-repeat;
		}

		&.overlaid .overlay
		{
			display: block;
		}
	}
}

.o-hidden
{
	overflow: hidden !important;
	overflow-y: hidden !important;
}

.custom-control-label.small
{
    padding-top: 0.15rem;
}

.custom-control:not(.custom-switch)
{
	input:not(:checked) ~ .custom-control-label::before
	{
		border-width: 1px;
		background-color: #fff;
	}
		
	.custom-control-input:focus ~ .custom-control-label::before
	{
		box-shadow: 0 0 0 0.15rem rgba(225, 226, 226, 0.5);
	}

	.custom-switch .custom-control-label::after
	{
		left: -3.3rem;
	}
}

.custom-control-input:focus ~ .custom-control-label::before
{
    //box-shadow: 0 0 0 0.15rem $custom-control-indicator-active-bg;
}
.custom-control-input:checked:focus ~ .custom-control-label::before
{
    box-shadow: 0 0 0 0.15rem rgba(76, 143, 233, 0.5);
}
.row.animated
{
	> *
	{
		transition: max-width 200ms ease-out,
					flex-basis 200ms ease-out
	}
}

.col-narrow {
    flex: 0 0 73px;
	max-width: 73px;
	
	.questions
	{
		display: none !important;
	}
}


input:focus + .dz-message
{
	border: 1px solid $primary;
}

input.is-invalid + .dz-message
{
	border: 1px solid $danger;
}

.avatar
{
	background-color: transparent;
}

.avatar .fe
{
	font-size: 1.5rem;
}
.avatar-xs .fe
{
	font-size: 1rem;
	margin-top: -1px;
}

.avatar-group-vertical {
	a.lift:hover
	{
		z-index: 999;
	}
}

.add-project-copy .copy-canvas
{
	
	display: inline-block;

	.overlay
	{	
		cursor: crosshair;
	}
}

.canvas-container
{
	max-height: 80vh;
	overflow-y: scroll;
	padding-right: 0
}
.copy-canvas
{
	position: relative;
	user-select: none;
	background: #000;

	img
	{
		max-width: 100%;
		pointer-events: none;
		opacity: 0.7;
	}

	.overlay
	{
		background-size: contain;
		background-position: 0 0;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.box
	{
		position: absolute;
		border: 3px dashed $primary;

		.badge
		{
			position: absolute;
			top: -23px;
			left: 0;
		}

		button
		{
			position: absolute;
			right: 0;
			z-index: 99;
			top: -31px;
			color: #fff;
			line-height: 1;
		}
			
		&.drawing
		{
			*
			{
				display: none;
			}
		}
	}

	&.drawing
	{
		cursor: move;

		.box
		{
			pointer-events: none;
		}
	}
}

.comment .design-block
{
}

.nav-tabs .avatar-img
{
	position: relative;
	border: solid 1px $gray-500;

	.badge
	{
		position: absolute;
		top: -3px;
		left: -3px;
	}
}

a.active .avatar-img .badge
{
	color: #fff;
	background-color: $primary;
}


.glow
{
    animation: glowing 1300ms infinite;
}

@keyframes glowing {
	0% {
		box-shadow: 0 0 3px $primary;
	}
	50% {
		box-shadow: 0 0 15px $primary;
	}
	100% {
		box-shadow: 0 0 3px $primary;
	}
}

.spinner-4x3
{
	padding-top: 75%;
	position: relative;

	.spinner-border
	{
		position: absolute;
		top: calc(50% - 15px);
		left: calc(50% - 15px);
	}
}

.messages
{
	overflow-y: scroll;
	height: 100%;
	max-height: 100%;

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	&::-webkit-scrollbar // webkit
	{
		display: none;
	}

	.message
	{
		border: 1px solid #333;
	}

	.comment-text
	{
		text-align: left;
	}
}

.input-group-btn {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    border-radius: 0.375rem;
}


.toaster
{
	position: relative;
	pointer-events: none;

	> div {
		position: fixed;
		top: 30px;
		left: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		z-index: 99;
	}
}

.toast
{
	transition: opacity 150ms ease-in;
	opacity: 0;
	pointer-events: all;
	flex-basis: auto;
	margin: 0 auto;
	max-width: none;

	&.fadeIn
	{
		opacity: 1;
	}

	&.success
	{
		box-shadow: 0 0 1rem fade-out($success, .8);
		border-left: solid 2px $success;
	}

	&.danger
	{
		box-shadow: 0 0 1rem fade-out($danger, .8);
		border-left: solid 2px $danger;
	}
}


.flex-column
{
	display: flex;
	flex-direction: column;
}

@include media-breakpoint-up(md)
{
	.border-md-left
	{
		border-left: $border-width solid $border-color !important;
	}
}

.mobile-blocker
{
	position: absolute;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	background: #fff;
}


.spinme-left {
  display: inline-block;
  padding: 15px 20px;
  font-size: 14px;
  color: #ccc;
  border-radius: 30px;
  line-height: 1.25em;
  font-weight: 100;
  opacity: 0.2;
}

.chat-typing
{
	margin: 0;
	width: 50px;
	text-align: center;

	> div
	{
		width: 8px;
		height: 8px;
		border-radius: 100%;
		display: inline-block;
		-webkit-animation: sk-bouncedelay 1.1s infinite ease-in-out both;
		animation: sk-bouncedelay 1.1s infinite ease-in-out both;
		background: $gray-600;
	}

	.bounce1
	{
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}

	.bounce2 {
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
	}
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}


*[aria-title]
{
	position: relative;
	z-index: 1;

	&::before
	{
		display: block;
		content: attr(aria-title);
		border-radius: 5px;
		background-color: #95AAC9;
		color: #fff;
		font-size: 0.7rem;
		line-height: 1;

		position: absolute;
		z-index: 999;
		top: -30px;
		padding: 4px 6px;

		left: 50%;
		width: auto;
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		transform: translateX(-50%);
		white-space: nowrap;

		pointer-events: none;
		transition: opacity 100ms ease-in-out;
		opacity: 0;
	}


	&:hover::before
	{
		opacity: 1;
	}

	&::after
	{
		display: block;
		content: '';
		width: 0px;
		height: 0px;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid #95AAC9;

		position: absolute;
		z-index: 998;
		top: -11px;

		left: 50%;
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		transform: translateX(-50%);

		pointer-events: none;
		transition: opacity 100ms ease-in-out;
		opacity: 0;
	}

	
	&.aria-danger::before
	{
		background-color: $danger;
	}
	&.aria-danger::after
	{
		border-top-color: $danger;
	}

	&:hover::after
	{
		opacity: 1;
	}
}

.avatar-chat
{
	width: 40px !important;
	height: 40px !important;
}

.btn-light
{
	background-color: #F9FBFD;
	border-color: #D2DDEC;
	
	&.btn-outline-secondary:hover {
		color: #FFFFFF;
		background-color: #6E84A3;
		border-color: #6E84A3;
	}
}

.expander
{
    max-height: 0px;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
	
	&.expanded
	{
		max-height: 10000px;
		transition: max-height 0.25s ease-out;
		overflow: visible;
	}
}

.align-items-flex-end
{
	align-items: flex-end;
}

.modal
{
	backdrop-filter: blur(10px);
	background-color: rgba(0,0,0,0.025);
	
	&.fade .modal-dialog
	{
		transition-duration: 100ms;
		transition-timing-function: cubic-bezier(0.54, 1.18, 0.83, 0.97);;
	}
}

.modal-dialog .card-body
{
	max-height: 500px;
}

.modal-dialog-fullscreen
{
	height: calc(100% - 80px);
	min-height: calc(100% - 80px);
	max-height: calc(100% - 80px);
	width: calc(100% - 80px);
	max-width: calc(100% - 80px);
	margin: 20px auto !important;

	.modal-content
	{
		height: 100%;
	}

	.modal-card
	{
		overflow: hidden;
	}

	iframe
	{
		width: 100%;
		height: calc(100% - 60px);
		border: 0;
	}
}


.modal-dialog-full-height
{
	height: calc(100% - 40px);
	min-height: calc(100% - 40px);
	max-height: calc(100% - 40px);
	margin: 20px auto !important;

	.modal-content
	{
		height: 100%;
	}

	.modal-card
	{
		overflow: hidden;
	}
}

.nav-tabs .nav-link:focus
{
	text-decoration: underline;
}

.progress-radial
{
	position: relative;

	span
	{
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		text-align: center;
		margin-top: -0.45rem;
		font-size: 1rem;
		line-height: 1rem;
		font-weight: 500;
	}
}

.break-after:after {
    content:"\a";
    white-space: pre;
}


.dropzone 
{
	
	cursor: pointer;
	
	*
	{
		pointer-events: none;
	}
}

.card-highlight
{
    animation: card-highlight 1300ms;
}

@keyframes card-highlight {
	0% {
		background-color: $primary;
	}
	100% {
		background-color: #fff;
	}
}

.form-control.stripe-element
{
	padding-top: 0.7rem;
}

.form-control-focused
{
	border-color: $primary;
}

.form-control-disabled
{
	background-color: $gray-200;
}

.p-sticky
{
	position: sticky;
	top: 20px;
}

.btn-outline-secondary-danger
{
	@extend .btn-outline-secondary;

	&:hover, &:active
	{
		color: #FFFFFF !important;
		background-color: $danger !important;
		border-color: $danger !important;

		&:focus
		{
			box-shadow: 0 0 0 0.15rem rgb(234 85 112 / 50%) !important;
		}
	}

	
}

.scroller
{
	height: calc(100% - 66px);
	overflow-x: hidden;
	overflow-y: scroll;

	&::-webkit-scrollbar-track
	{
		border-radius: 8px;
		background-color: #fff;
	}

	&::-webkit-scrollbar
	{
		width: 8px;
		background-color: #fff;
	}

	&::-webkit-scrollbar-thumb
	{
		border-radius: 8px;
		background-color: $gray-400;
	}
}




.order-n1
{
	order: -1;
}


.w-80 {
  width: 80%;
  color: red;
}


// -------------------------------

.card
{
	//overflow: hidden;
}

.shrink
{
	width: 1px;
	white-space: nowrap;
}

.d-md-tablecell
{
	display: table-cell;
}

.border-left-success
{
	border-left: 3px $success solid;
}


.border-left-secondary
{
	border-left: 3px $secondary solid;
}


.border-left-danger
{
	border-left: 3px $danger solid;
}